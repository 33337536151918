import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, REVALIDATE_TIME, WIDGETS_API } from '@/helpers/variables';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { IWidgetsData } from '@/interfaces/widgets.interface';

interface IHomePage extends IWidgetsData {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  oneNewBook: IBooksPageData;
  onePopularBook: IBooksPageData;
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks, oneNewBook, onePopularBook, widgetsData }) => {
  return <Home popularBooks={popularBooks} newBooks={newBooks} oneNewBook={oneNewBook} onePopularBook={onePopularBook} widgetsData={widgetsData} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const [popularRes, newRes] = await Promise.all([fetch(`${API_URL}satellite/books/popular?page=1&page_size=20&sort_visited=true`), fetch(`${API_URL}satellite/books/news?page=1&page_size=12`)]);
  const [popularBooks, newBooks] = await Promise.all([popularRes.json(), newRes.json()]);

  const oneNewBook = newBooks.data.length > 0 ? newBooks.data.shift() : [];
  const onePopularBook = popularBooks.data.length > 0 ? popularBooks.data.shift() : [];

  const widgetsDataRest = await fetch(`${WIDGETS_API.main}`);
  const widgetsData = await widgetsDataRest.json();
  // console.log(`Memory usage: ${JSON.stringify(process.memoryUsage())}`);

  return { props: { popularBooks, newBooks, oneNewBook, onePopularBook, widgetsData }, revalidate: 3000 };
};

export default HomePage;
